import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './core/views/full-layout/full-layout.component';
import { SharedModule } from './shared/shared.module';
import { OltAngularDateHttpInterceptor, OltConfigServiceBase, OltGlobalErrorHandler } from '@outerlimitstech/ngx-app-core';
import { ConfigService } from './core/services/config.service';
import { AppSettingsService, GeneralInterceptor, StorageService, appSettingsFactory } from './core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { BaseConfigService, BaseStorageService } from 'bgcslib';

@NgModule({ declarations: [
        AppComponent,
        FullLayoutComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule], providers: [
        {
            provide: OltConfigServiceBase,
            useClass: ConfigService
        },
        {
            provide: BaseConfigService,
            useClass: ConfigService,
        },
        {
            provide: BaseStorageService,
            useClass: StorageService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GeneralInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OltAngularDateHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: OltGlobalErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appSettingsFactory,
            multi: true,
            deps: [AppSettingsService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
